import { useEffect, useState } from 'react'
import { Section } from '../../atoms/Section'

export const Projects = () => {
    const [repos, setRepos] = useState([
        {
            url: 'test',
            name: 'test',
            description: 'test',
            language: 'test',
        },
    ])

    useEffect(() => {
        const getData = async () => {
            const req = await fetch(
                'https://api.github.com/users/sarmijavier/repos',
                {
                    headers: {
                        Authorization: `Bearer ${'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'}`,
                        Accept: 'application/vnd.github+json',
                    },
                }
            )

            const cont = await req.json()
            const contWithDescription = cont.filter(
                (repo) => repo.description !== null
            )
            setRepos(contWithDescription)
        }
        getData()
    }, [])

    console.log(repos)
    return (
        <Section
            id="projects"
            design={'flex flex-col mx-16 my-32 w-100 items-center'}
        >
            <div className="grid gap-4 lg:grid-cols-2">
                {repos.map(({ name, url, language, description, svn_url }) => (
                    <div key={url} className="boxProject p-8 m-4 text-white-50">
                        <h1>Name: {name}</h1>
                        <br />
                        <a
                            className="bg-orange-50"
                            href={svn_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            Project Link
                        </a>
                        <p>Description: {description}</p>
                        <br />
                        <p>technologies used: {language}</p>
                    </div>
                ))}
            </div>
        </Section>
    )
}
