
import { useState } from 'react';
import { ContactMe } from '../organisms/Main/Contact';
import { Main } from '../organisms/Main/Main';
import { Projects } from '../organisms/Main/Projects';
import { Experience } from '../organisms/Main/Skills';
import { TemplatePorfolio } from '../templates/TemplatePortfolio';

export const Portfolio = () => {

	const [navbarButton, setNavbarButton] = useState('About')


	const renderComponent = () => {
		switch (navbarButton) {
			case 'About':
				return <Main />
			case 'Projects':
				return <Projects />
			case 'Experience':
				return <Experience />
			case 'Contact me':
				return <ContactMe />
			default:
				return <h1>NAda</h1>
		}
	}

	return (
			<TemplatePorfolio navbarButton={navbarButton} setNavbarButton={setNavbarButton}>
				{renderComponent()}
			</TemplatePorfolio>
	)

}
