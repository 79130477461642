
import { Footer } from '../organisms/Footer/Footer';
import { Navbar } from '../organisms/Navbar/Navbar';


export const TemplatePorfolio = ({ children, navbarButton, setNavbarButton }) => {

	return (
		<div className="min-h-screen bg-blue-50 font-mono">
			<Navbar navbarButton={navbarButton} setNavbarButton={setNavbarButton} />
				{children}
			<Footer />
		</div>
	)
}