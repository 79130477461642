

export const ListItem = ({ children, design, anchor = false, setMenu, menu, mobile, navbarButton, setNavbarButton }) => {

	const handleClick = (content) => {
		setNavbarButton(content)
	}

	return (
		<>
			<li onClick={() => handleClick(children)} className={`${design} ${navbarButton === children && 'bg-orange-50 text-xl'} items-center `}>{ children }</li>	
		</>
	)
}