import { Container } from "../../atoms/Container"
import { Image } from "../../atoms/Image"
import mainImage from '../../../static/images/undraw_programming_re_kg9v.svg'

export const Main = () => {

	return (
		<main className='grid grid-cols-1 mx-12 lg:grid-cols-2 justify-items-center lg:mx-16 my-32 w-100 items-center'>
			<Container design={'w-full lg:w-100 self-center'} >
				<p className="text-white-50 text-justify">
					Hi! my name is Javier, an experienced and passionate software engineer. <br/>  <br/>
					I'm working at Wazoku as a Software Engineer <br/> <br/>
					Right now I'm focused on learning Software Engineering and AI. <br/>
					<br/>
					<br/>
					Technologies:
					<ul>
						<li>👉 Python</li>
						<li>👉 Javascript (ES6+)</li>
						<li>👉 AWS</li>
					</ul>
				</p>
			</Container>
			<Container design={'mx-8 px-8 mt-8 w-full lg:mx-12 lg:px-16 '}>
				<Image alt={'mainImage'} src={mainImage} />
			</Container>
		</main>
	)
}