const navbarData = [
	{
		title: 'About',
		design: "m-4 rounded	hover:bg-orange-50 text-xl",
	},
	{
		title: 'Experience',
		design: "m-4 rounded	hover:bg-orange-50 text-xl",
	},
	{
		title: 'Projects',
		design: "m-4 rounded	hover:bg-orange-50 text-xl",
	},
	{
		title: 'Contact me',
		design: "m-4 rounded	hover:bg-orange-50 text-xl",
	}
]




export {
	navbarData,
}