import { Section } from '../../atoms/Section'

export const Experience = () => {
    return (
        <Section design={'flex flex-col mx-16 my-32 w-100 items-center'}>
            <div className='w-full'>
                <h1 className="text-white-50 text-lg">Software Engineer</h1>
                <h1 className="text-white-50 text-lg">Wazoku</h1>
                <h2 className="text-white-50">May 2023 - Present</h2>
                <br />
                <h1 className="text-white-50 text-md">
                    Key Qualifications & Responsibilities
                </h1>
                <br />
                <ul className="text-white-50">
                    <li>
                        👉 ....
                    </li>
                </ul>

            </div>
            <br />
            <div className="border-t-4 text-white-50 w-full">
                <br />
                <h1 className="text-white-50 text-lg">Full Stack Developer</h1>
                <h1 className="text-white-50 text-lg">Datarte</h1>
                <h2 className="text-white-50">Feb 2021 - Aug 2022</h2>
                <br />
                <h1 className="text-white-50 text-md">
                    Key Qualifications & Responsibilities
                </h1>
                <br />
                <ul className="text-white-50">
                    <li>
                        👉 Supported the creation of two full stack web
                        applications (
                        <a
                            className="bg-orange-50"
                            target="_blank"
                            rel="noreferrer" 
                            href="https://www.datarte.art/" 
                        >
                            Datarte
                        </a>{' '}
                        and
                        <a
                            className="bg-orange-50"
                            target="_blank"
                            rel="noreferrer" 
                            href="https://otrasmanerasdeocuparelespaciopublico.com/"
                        >
                            {' '}
                            OtrasManeras
                        </a>
                        ) which showed, shared, downloaded and managed artists’s
                        artworks.
                    </li>
                    <li>
                        👉 Created 1 backend application which migrated
                        information from different marketing platforms to other
                        ones.
                    </li>
                    <li>
                        👉 Supported the maintenance of two web applications
                        from the UK (
                        <a
                            className="bg-orange-50"
                            target="_blank"
                            rel="noreferrer" 
                            href="https://cityrelay.com/"
                        >
                            CityRelay
                        </a>{' '}
                        and{' '}
                        <a
                            className="bg-orange-50"
                            target="_blank"
                            rel="noreferrer" 
                            href="https://cityrelaysolutions.com/"
                        >
                            {' '}
                            CityRelaySolutions
                        </a>
                        ).
                    </li>
                    <li>
                        👉 Collaborated with the app development to identify
                        problems, testing methods, and best practices.
                    </li>
                    <li>
                        👉 Managed ongoing projects projects by training and
                        supervising team members.
                    </li>
                    <li>
                        👉 Managed time-sensitive updates, such as changing
                        content and upgrading databases.
                    </li>
                    <li>
                        👉 The technology used like ReactJs, Gatsby, Wordpress,
                        Flask, Javascript, Python, Sql, AWS, Nginx, Apache,
                        Cypress, Pytest, Selenium, Jupyter notebooks, Metabase.
                    </li>
                    <li>
                        👉 Created scrapers that get data from specific
                        websites.
                    </li>
                </ul>
                <br />
            </div>
            <div className="border-t-4 text-white-50 w-full">
                <br />
                <h1 className="text-white-50 text-lg">Full Stack Developer</h1>
                <h1 className="text-white-50 text-lg">
                    Catholic University of Colombia and Complutense University
                    of Madrid
                </h1>
                <h2 className="text-white-50">Feb 2020 - Dic 2020</h2>
                <br />
                <h1 className="text-white-50 text-md">
                    Key Qualifications & Responsibilities
                </h1>
                <br />
                <ul className="text-white-50">
                    <li>👉 Researched how the Fitbit SDK works.</li>
                    <li>
                        👉 Built small prototypes to achieve specific goals such
                        as get heart rate date or calories.
                    </li>
                    <li>
                        👉 Collaborated with a group of three professors and one
                        student to solve problems and explain how the Fitbit SDK
                        worked and how an open source project might have helped
                        us.
                    </li>
                    <li>
                        👉 Developed a complete smart watch app to get all the
                        physiological data from the smartwatch device. This data
                        was sent to a web-socket that was deployed on Heroku.
                        Therefore, the data was saved in a SQL database.
                    </li>
                    <li>
                        👉 Supported the investigation work from one bachelor’s
                        student and a project that was guided by a professor
                        from the Complutense University of Madrid.
                    </li>
                </ul>
                <br />
                <spam>
                    <a
                        className="bg-orange-50"
                        target="_blank"
                        rel="noreferrer" 
                        href="https://github.com/sarmijavier/fitbit_app"
                    >
                        project
                    </a>
                </spam>
                <br />
                <br />
            </div>

            <div className="border-t-4 text-white-50 w-full">
                <br />
                <h1 className="text-white-50 text-lg">Full Stack Developer</h1>
                <h1 className="text-white-50 text-lg">
                    Catholic University of Colombia
                </h1>
                <h2 className="text-white-50">Jun 2020 - Dic 2022</h2>
                <br />
                <h1 className="text-white-50 text-md">
                    Key Qualifications & Responsibilities
                </h1>
                <br />
                <ul className="text-white-50">
                    <li>
                        👉 Created a full stack web application ({' '}
                        <a
                            className="bg-orange-50"
                            href="https://jsjpython.herokuapp.com/"
                        >
                            JSJPYTHON
                        </a>{' '}
                        ) which showed videos about how to learn python.
                    </li>
                    <li>
                        👉 Collaborated with the app development to identify
                        problems, testing methods, and best practices.
                    </li>
                    <li>
                        👉 Collaborated with a group of one professor and two
                        students to solve problems and build this project.
                    </li>
                    <li>
                        👉 The technology used like Python, Flask, Heroku and
                        Youtube.
                    </li>
                </ul>
                <br />
            </div>
        </Section>
    )
}
