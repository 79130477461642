import { Section } from "../../atoms/Section"
import Linkedin from "../../../static/icons/icons8-linkedin.svg"
import Github from "../../../static/icons/icons8-github.svg"
import Twitter from "../../../static/icons/icons8-twitter.svg"
import Instagram from "../../../static/icons/icons8-instagram.svg"

import { Image } from "../../atoms/Image"


export const ContactMe = () => {

	return (
		<Section design={'flex flex-col mx-16 my-32 w-100 items-center'}>
			<p className="text-center text-white-50">
				I invite you to get in touch through social medias as well.
				<br/>
				Have a good day 👋
			</p>
			<div className="flex">
				<a href="https://www.linkedin.com/in/javier-sarmiento-28085a19a/" target="_blank" rel="noreferrer">
					<Image alt={'Linkedin'} src={Linkedin} />
				</a>
				<a href="https://github.com/sarmijavier" target="_blank" rel="noreferrer">
					<Image alt={'Github'} src={Github} />

				</a>
				<a href="https://twitter.com/SarmiJavier" target="_blank" rel="noreferrer">
					<Image alt={'Twitter'} src={Twitter} />
					
				</a>
				<a href="https://www.instagram.com/sarmijavier/" target="_blank" rel="noreferrer">
					<Image alt={'Instagram'} src={Instagram} />		
				</a>
			</div>
		</Section>


	)
}