import { ItemsList } from "../../molecules/ItemsList"
import { H1 } from "../../atoms/H1";
import { Section } from "../../atoms/Section";



export const Footer = () => {
	return (
		<footer className='flex justify-center p-16 text-center'>
			<Section>
				<ItemsList design={'flex justify-center'}>
					{

					}
				</ItemsList>
				<H1 design='text-sm	 md:text-2xl text-white-50'>
					Made by Javier Sarmiento. 🛸
				</H1>
			</Section>
		</footer>
	)
}