import { ListItem } from "../../atoms/ListItem"
import { ItemsList } from "../../molecules/ItemsList"
import { navbarData } from "../../../staticData/data";

export const Navbar = ({navbarButton, setNavbarButton}) => {

	return (
		<nav className='lg:flex w-full justify-between'>
			<div className="p-4 mx-4 border-b-2 text-white-50 text-center ">
				<h1>
					JS
				</h1>
			</div>
			<ItemsList design={"flex flex-col justify-center lg:flex-row text-white-50 cursor-pointer"}>
				{
					navbarData.map(({ title, design }, index) => (
						<ListItem key={index} children={title} design={design} navbarButton={navbarButton} setNavbarButton={setNavbarButton}/>
					))
				}
			</ItemsList>
		</nav>
	)
}